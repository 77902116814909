import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const NavContainer = styled.div`
  text-align: center;
`;

const StyledButton = styled(Button)`
  background-color: #414192;
  color: #fff;
  border-radius: 25px;
  padding: 6px 12px;
  margin: 5px;

  &:hover {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background-color: #ea3437;
  }
`;

const Nav = () => (
  <NavContainer id="menu">
    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/"
      activeClassName="activeNav"
      id="pratos-a-la-carte"
    >
      pratos à la carte
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/pasteis"
      activeClassName="activeNav"
    >
      pastéis
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/lanches"
      activeClassName="activeNav"
    >
      lanches
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/batata-recheada"
      activeClassName="activeNav"
    >
      batata recheada
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/porcoes"
      activeClassName="activeNav"
    >
      porções
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/pizzas"
      activeClassName="activeNav"
    >
      pizzas
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/mini-pizzas"
      activeClassName="activeNav"
    >
      mini pizzas
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/bebidas"
      activeClassName="activeNav"
    >
      bebidas
    </StyledButton>

    <StyledButton
      variant="contained"
      disableElevation
      component={Link}
      to="/fotos"
      activeClassName="activeNav"
    >
      fotos
    </StyledButton>
  </NavContainer>
);

export default Nav;
