/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('typeface-roboto');
require('typeface-lora');

exports.onServiceWorkerUpdateReady = () => {
  window.location.reload();
};

exports.shouldUpdateScroll = () => {
  return false;
};

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      if (
        registration.active.scriptURL ===
        'https://pastelariadogordao.com.br/service-worker.js'
      ) {
        registration.unregister();
      }
    }
  });
}
