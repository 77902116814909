// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-batata-recheada-js": () => import("./../../../src/pages/batata-recheada.js" /* webpackChunkName: "component---src-pages-batata-recheada-js" */),
  "component---src-pages-bebidas-js": () => import("./../../../src/pages/bebidas.js" /* webpackChunkName: "component---src-pages-bebidas-js" */),
  "component---src-pages-fotos-js": () => import("./../../../src/pages/fotos.js" /* webpackChunkName: "component---src-pages-fotos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lanches-js": () => import("./../../../src/pages/lanches.js" /* webpackChunkName: "component---src-pages-lanches-js" */),
  "component---src-pages-mini-pizzas-js": () => import("./../../../src/pages/mini-pizzas.js" /* webpackChunkName: "component---src-pages-mini-pizzas-js" */),
  "component---src-pages-pasteis-js": () => import("./../../../src/pages/pasteis.js" /* webpackChunkName: "component---src-pages-pasteis-js" */),
  "component---src-pages-pizzas-js": () => import("./../../../src/pages/pizzas.js" /* webpackChunkName: "component---src-pages-pizzas-js" */),
  "component---src-pages-porcoes-js": () => import("./../../../src/pages/porcoes.js" /* webpackChunkName: "component---src-pages-porcoes-js" */)
}

